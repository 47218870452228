(function ($) {
    const videoWrapper = $('.video-embed');
    const button = videoWrapper.parents('.form-section').find('[data-behaviour="next-step"]');
    const videoElement = videoWrapper.find('video');

    // get video length, fallback to 90s if it doesnt work
    let duration = videoElement[0].duration ? videoElement[0].duration : 90;
    duration = Math.floor(duration);

    // hide button
    button.addClass('is-hidden');

    videoElement.on("timeupdate", function () {
        const seconds = Math.floor(this.currentTime);

        if (seconds >= (duration)) {
            button.removeClass('is-hidden');

            $([document.documentElement, document.body]).animate({
                scrollTop: button.offset().top
            }, 2000);
        }
    });

    $('[data-behaviour="play-video"]').on('click', function (e) {
        e.preventDefault();

        videoElement[0].play();
        $(this).addClass('u-visually-hidden');
    });
})(jQuery);
