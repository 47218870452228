(function ($) {
    function nextStep(step) {
        const findStep = $('.step[data-step="' + step + '"]');
        let activeStep = $('.step.is-active');

        findStep.addClass('is-active');
        activeStep.removeClass('is-active');
    }


    $('[data-behaviour="next-step"]').on('click', function (e) {
        e.preventDefault();

        nextStep($(this).data('step-target'));
    });

    document.addEventListener("nextStep", function (e) {
        nextStep(e.detail)
    });

    $('[data-behaviour="update-hidden-field"]').on('change', function () {
        const hiddenField = $('[name="quiz_answer"]');
        var valueSelected = this.value;

        hiddenField.val(valueSelected);

        $('[data-step-target=3]').removeClass('is-hidden');
    });
})(jQuery);
